import { USER_MODULE } from '@/store/modules/user.module';
import { useToast } from 'primevue/usetoast';
import { computed, Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { ActivityReportConfig, INDEX_LOOKUPS, SEND_DAYS, SendDayData } from '@/models/activity-dissemination';

import ActivityReportConfigService from '@/services/activityReportConfigService';
import { FilterModel } from '@/models/search';
import { TermsFilter } from '@/models/filters';
import { findFilter } from '@/utils/filters';
import { Validation } from '@vuelidate/core';
import { SaveActivityReportConfigRequest } from '@/models/activity-dissemination/saveActivityReportConfigRequest';
import { flatMap, sum } from 'lodash';
import { onError, onSuccess } from '@/utils/toast/toastUtils';
import { useStore } from 'vuex';
import UserService from '@/services/userService';

export default function useActivityReportConfigActions(selectedConfig: Ref<ActivityReportConfig>, sendDayData: Ref<SendDayData[]>, v$: Ref<Validation>, submitted: Ref<boolean>) {
  const loading = ref(false);
  const error = ref<any>(null);

  const route = useRoute();
  const router = useRouter();
  const toast = useToast();
  const store = useStore();

  const getData = async () => {
    loading.value = true;
    error.value = null;
    const id = route.params['id'].toString();
    const userId = route.params['userId'].toString();

    if (!id) {
      loading.value = false;
      await updateFromCurrentUser(userId);
      // load profile user's default search filters
      selectedConfig.value.filterModel = store.state.user.defaults ?? { filters: [], indices: INDEX_LOOKUPS };
      return;
    }

    try {
      selectedConfig.value = (await ActivityReportConfigService.get(id)) || selectedConfig.value;
      const companyId = getCompanyId(selectedConfig.value.filterModel);
      selectedConfig.value.companyId ??= companyId;
      await updateCurrentUser(userId);
      updateSendDays();
    } catch (err) {
      error.value = err;
    }
    loading.value = false;
  };

  const updateCurrentUser = async (userId: string) => {
    const user = await UserService.get(userId);
    store.commit(`user/${USER_MODULE.SET_CURRENT_USER}`,
      {
        email: user.email,
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        searchFields: user.activityFields,
        clients: user.companies,
        isClientUser: user.role === 'Client'
      });
  };

  const saveData = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    }
    try {
      selectedConfig.value = await ActivityReportConfigService.save(saveRequest.value);
      onSuccess(toast, successMessage.value);
      submitted.value = false;
      if (isNew.value) {
        router.push(`/dissemination-contact/${selectedConfig.value.userId}/${selectedConfig.value.id}`);
      }
    } catch (err) {
      onError(toast, `There was a error on saving the Activity Report Configuration`);
    }
  };

  const validate = async () => {
    submitted.value = true;
    const isValid = (await v$.value.$validate()) ?? true;
    return isValid;
  };


  const getCompanyId = (filterModel: FilterModel) => {
    if (!filterModel) {
      return null;
    }
    const companyFilter = findFilter<TermsFilter>(filterModel.filters, 'Clients');
    return companyFilter?.value[0].id || 0;
  };

  const updateSendDays = () => {
    sendDayData.value.forEach(d => {
      d.selected = selectedConfig.value.sendDays.includes(d.name);
    });
  };

  const updateFromCurrentUser = async (userId: string) => {
    try {
      if (!store.state.user || store.state.user.userId !== userId) {
        await store.dispatch(`user/${USER_MODULE.GET_CURRENT_USER}`, { userId });
      }

      selectedConfig.value.userId = store.state.user.userId;
      selectedConfig.value.email = store.state.user.email;
      selectedConfig.value.firstName = store.state.user.firstName;
      selectedConfig.value.lastName = store.state.user.lastName;
    } catch (e) {
      console.error(e);
    }
  };

  const saveRequest = computed(() => <SaveActivityReportConfigRequest>{
    id: selectedConfig.value.id,
    userId: selectedConfig.value.userId,
    aggregateSubsequent: selectedConfig.value.aggregateSubsequent,
    disabled: selectedConfig.value.disabled,
    reportType: selectedConfig.value.reportTypeEnum,
    webhookUrl: selectedConfig.value.webhookUrl,
    filters: flatMap(selectedConfig.value.filterModel.filters),
    indices: selectedConfig.value.filterModel.indices,
    includeActivityQualityColumn: selectedConfig.value.includeActivityQualityColumn,
    sendDays: sendDayData.value
      .filter(c => c.selected)
      .map(c => SEND_DAYS[c.name].id)
  });

  const isNew = computed(() => !route.params['id']);
  const successMessage = computed(() =>
    isNew.value ?
      `Activity Report Configuration was successfully created 👍` :
      `Activity Report Configuration was successfully updated 👍`
  );

  return { sendDayData, loading, error, getData, saveData, getCompanyId, isNew };
}
