import {ActivityReportType, ActivityReportTypeString, SEND_DAYS} from '.';
import {FriendlyTextModel} from '../common/friendlyTextModel';
import {IntLookup, Lookup} from '../lookup';
import {FilterModel} from '../search';

export class ActivityReportConfig {
  id: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  onlineId: string | null = null;
  aggregateSubsequent: boolean;
  reportType: ActivityReportTypeString;
  sendDays: string[];
  lastSentOn?: Date;
  includeActivityQualityColumn?: boolean = true;
  includeEmailAttachement?: boolean = false;
  disabled: boolean;
  sendToWebhook: boolean;
  webhookUrl: string;
  filterModel: FilterModel;
  
  constructor(fields: Partial<ActivityReportConfig> = {}) {
    if (fields) {
      Object.assign(this, fields);
    }
    this.reportType ||= 'Consolidated';
    this.onlineId = this.onlineId || null;
    this.filterModel = this.filterModel || { filters: [], indices: [] };
  }
  
  get reportTypeEnum() {
    return ActivityReportType[this.reportType];  
  }
  
  get isConsolidated() {
    return this.reportTypeEnum === ActivityReportType.Consolidated;
  }

  get isWebhook() {
    return this.reportTypeEnum === ActivityReportType.Webhook;
  }
}

export class ActivityReportConfigRow {
  id: string;
  aggregateSubsequent: boolean;
  reportType: ActivityReportTypeString;
  sendDays: string[];
  lastSentOn?: Date | string;
  disabled: boolean;
  companies: IntLookup[];
  onlines: Array<Lookup<string>>;
  filterModel: FilterModel;
  userId: string;

  constructor(props: Partial<ActivityReportConfigRow> = {}) {
    Object.assign(this, props);
    
    if (typeof props.lastSentOn === 'string') {
      const a = /\/Date\((\d*)\)\//.exec(props.lastSentOn);
      if (a) {
        this.lastSentOn = new Date(+a[1]);
      }
    }
  }

  get editUrl(): string {
    return `/dissemination-contact/${this.userId}/${this.id}`;
  }
  
  get reportTypeEnum(): ActivityReportType {
    return ActivityReportType[this.reportType];  
  }
  
  get icon(): string {
    return getIcon(this.reportTypeEnum, this.aggregateSubsequent);
  }

  get iconTooltip(): string {
    return getIconTooltip(this.reportTypeEnum, this.aggregateSubsequent);
  }
  
  get scheduleTooltip(): string {
    return this.disabled
      ? 'Not Enabled for Activity Reporting'
      : 'Enabled for Activity Reporting';
  }

  get scheduleClass(): string {
    return this.disabled
      ? 'material-icons mx-2 color-gray'
      : 'material-icons mx-2 color-teal';
  }
}

export function toFriendlyTextModel(config: ActivityReportConfig | ActivityReportConfigRow): FriendlyTextModel {
  return {
    filters: config.filterModel.filters,
    activityTypes: config.reportTypeEnum === ActivityReportType.Individual 
      ? ['Customer Requests'] 
      : config.filterModel.indices.map(i => i.name),
    id: [config.userId, config.id],
    idType: 'ActivityReport',
    show: false,
    // NOTE: ActivityTypes are removed because indices are manipulated in the actual filter view
    contextSpecificHiddenFields: ['ActivityTypes', 'EligibilityDate', 'DateRange', 'RelativeDateRange']
  };
}

export const getIcon = (reportType: ActivityReportType, agg: boolean) => {
  switch (reportType) {
    case ActivityReportType.Consolidated:
      return agg ? 'playlist_add' : 'list';
    case ActivityReportType.Webhook:
      return 'webhook';
    case ActivityReportType.Individual:
    default:
      return 'mail';
  }
};

export const getIconTooltip = (reportType: ActivityReportType, agg: boolean) => {
  switch (reportType) {
    case ActivityReportType.Consolidated:
      return agg ? 'Aggregate Subsequent' : 'List Subsequent Individually';
    case ActivityReportType.Webhook:
      return 'Webhook';
    case ActivityReportType.Individual:
    default:
      return 'Individual';
  }
};
