import { SearchFieldMetaData, SearchFieldProp, searchFieldModelValue } from '@/components/searchFields/metadata';
import { DateRangeFilter, Filter, RelativeDateRange, RelativeDateRangeFilter } from '@/models/filters';
import { FieldCode } from '@/models/filters/fieldCode';
import { CompanyClaim } from '@/models/security';
import { FilterModel } from '@/models/search/filterModel.interface';
import { INDEX_MAP } from '@/models/analytics/activityTypes';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/models/common/dateFormats';

export interface DefaultFiltersRequest {
  userCompanies: CompanyClaim[];
  defaultQualityFlag: number;
}

export interface FilterProp {
  name: FieldCode;
  props: SearchFieldProp;
}

export const DEFAULT_DASHBOARD_FIELDS: FieldCode[] = [
  'DateRange',
  'Clients',
  'Onlines',
  'ReaderOrgs',
  'ReaderCompany',
  'Title',
  'Email',
  'DocumentTitle',
  'Region',
  'Country',
  'State',
  'City',
  'Quality',
  'OrgType',
  'ContentType',
  'ContentTopics',
  'BuyersJourneyStage',
  'OrgEmployees',
  'Revenue'
];

export function setValidationRules(searchFields: SearchFieldMetaData[], rules: Partial<Record<FieldCode, Array<(val: string) => any>>>) {
  Object.keys(rules).forEach(filterName => {
    const sf = searchFields.find(f => searchFieldModelValue(f, 'field') === filterName);
    if (!sf) { return; }
    sf.props = sf.props || {};
    sf.props.rules = rules[<FieldCode>filterName];
  });
}

export function setPropOverrides(searchFields: SearchFieldMetaData[], props: FilterProp[]) {
  searchFields
    .filter(m => props.some(x => x.name === searchFieldModelValue(m, 'field')))
    .forEach(m => {
      const localProp: SearchFieldProp = props.find(x => x.name === searchFieldModelValue(m, 'field'))?.props || {};
      m.props = Object.assign(<SearchFieldProp>m.props, localProp);
    });
}

export function findFilter<T extends Filter>(filters: Filter[], name: FieldCode): T {
  return filters.find(x => x.field === name) as T;
}

export function filterValueExists(filters: Filter[], name: FieldCode): boolean {
  const filter = findFilter(filters, name);
  return filter && !filter.isEmpty();
}

export function filterModelParams(filterModel: FilterModel): { filters: Filter[] } {
  return { filters: filterModel.filters };
}

export function toActivityTypes(filterModel: FilterModel) {
  return filterModel.indices.map(i => INDEX_MAP[i.id]);
}

export function toIndices(filterModel: FilterModel): { indices: string[] } {
  return { indices: filterModel.indices.map(x => x.id) };
}

export function dateRangeText(filters: Filter[]) {
  const dtFilter = findFilter<DateRangeFilter>(filters, 'DateRange');
  const relativeDateRangeFilter = findFilter<RelativeDateRangeFilter>(filters, 'RelativeDateRange');

  if (!dtFilter?.value) {
    return '';
  }

  let resultsFrom = 'RESULTS FROM';

  if (!relativeDateRangeFilter?.value || relativeDateRangeFilter.value === RelativeDateRange.Custom) {
    resultsFrom = `${resultsFrom} ${dayjs(dtFilter.value.from).format(DATE_FORMAT)} - ${dayjs(dtFilter.value?.to || new Date()).format(DATE_FORMAT)}`;
  } else {
    switch (relativeDateRangeFilter.value) {
      case RelativeDateRange.Last30Days:
        resultsFrom = `${resultsFrom} the last 30 days`;
        break;
      case RelativeDateRange.Last60Days:
        resultsFrom = `${resultsFrom} the last 60 days`;
        break;
      case RelativeDateRange.Last90Days:
        resultsFrom = `${resultsFrom} the last 90 days`;
        break;
      case RelativeDateRange.Last1Year:
        resultsFrom = `${resultsFrom} the last year`;
        break;
      case RelativeDateRange.Last2Years:
        resultsFrom = `${resultsFrom} the last 2 years`;
        break;
      case RelativeDateRange.Last1CalendarYear:
        resultsFrom = `${resultsFrom} the last calendar year`;
        break;
      case RelativeDateRange.Last2CalendarYears:
        resultsFrom = `${resultsFrom} the last 2 calendar years`;
    }
  }

  return resultsFrom.toUpperCase();
}



