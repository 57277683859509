<template>
  <div class="grid">
    <div class="col-12 pb-0">
      <div class="flex">
        <h1 class="line-height-1">
          Most Active Readers
        </h1>
      </div>
      <span class="p-secondary-color text-xs">{{ getDateRange() }}</span>
    </div>
    <cp-most-active-readers :filter-model="filterModel" />
    <cp-dashboard-filter v-model="filterModel" :show="displayFilter" :include-indices="true"
      @filter-submitted="filterSubmitted" @close-panel="closePanel" />
    <div class="filter-menu-wrapper">
      <a class="filter-menu" @click.stop="showFilter(true)">
        <img :src="require('../../assets/icons/dashboard-filters.svg')" alt="Filter Framework" width="52">
      </a>
    </div>
  </div>
</template>

<script lang="ts">

import MostActiveReadersVue from '@/components/reader-co-dashboard/MostActiveReaders.vue';
import DashboardFilter from '@/components/client-dashboard/DashboardFilter.vue';
import { onMounted, ref } from 'vue';
import useAuthenticatedUser from '@/use/authenticatedUser';
import useCurrentFilters from '@/use/currentFilters';
import { FilterModel } from '@/models/search';
import { dateRangeText } from '@/utils/filters';
import { cloneDeep } from 'lodash';

export default {
  components: {
    'cp-most-active-readers': MostActiveReadersVue,
    'cp-dashboard-filter': DashboardFilter
  },

  setup() {
    const filterModel = ref(<FilterModel>{ filters: [], indices: [], });
    let displayFilter = ref(false);

    onMounted(async () => {
      const { authenticatedUser } = useAuthenticatedUser();
      const { currentState } = useCurrentFilters(authenticatedUser);

      filterModel.value = <FilterModel>{
        filters: currentState.value.filters,
        indices: currentState.value.indices,
      };
    });

    const getDateRange = () => dateRangeText(filterModel?.value.filters);

    const filterSubmitted = (model: FilterModel) => {
      const filters = cloneDeep(model);
      filterModel.value = <FilterModel>{
        filters: filters.filters,
        indices: filters.indices,
      };
    };

    const closePanel = () => {
      showFilter(false);
    };

    const showFilter = (value) => {
      displayFilter.value = value;
    };

    return {
      filterModel,
      getDateRange,
      showFilter,
      closePanel,
      filterSubmitted,
      displayFilter,
    };
  }
};
</script>
