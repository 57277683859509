import UserService from '@/services/userService';
import { AppUser } from '@/models/appUser';
import { computed, ref, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
import { MSG_MUTATIONS } from '@/store/modules/msg.module';
import { Validation } from '@vuelidate/core';
import { AUTH_ACTIONS } from '@/store/modules/auth.module';

export default function useUserActions(
  selectedUser: Ref<AppUser>,
  v$: Ref<Validation>,
  saveSuccess: Ref<boolean>,
  submitted: Ref<boolean>,
  isMyProfile: Ref<boolean>,
  sendEmailToAdmin: Ref<boolean> = ref(true),
  sendOnboardingForm: Ref<boolean> = ref(false)
) {
  const msgDisplayTime = 3000;
  const loading = ref(false);
  const error = ref<any>(null);
  const user = ref(selectedUser.value);

  const router = useRouter();
  const toast = useToast();
  const store = useStore();

  const passwordResetMessage = computed(() => isMyProfile.value
      ? `Check your email for a password reset link`
      : `A reset password link has been sent to ${user.value.email}`);

  const createUser = async () => {
    const isValid = await _validateUser();
    // debugger;
    if (!isValid) {
      return;
    }

    try {
      selectedUser.value = await UserService.create({
        user: user.value,
        sendEmailToAdmin: sendEmailToAdmin.value,
        sendOnboardingForm: sendOnboardingForm.value,
      });
      _onSaveSuccess('User was successfully created 👍');
      router.push(`/admin/user/${selectedUser.value.id}`);
    } catch (err) {
      _onSaveFailure(null, err);
    }

    loading.value = false;
  };

  const updateUser = async () => {
    const isValid = await _validateUser();
    if (!isValid) {
      return;
    }
    try {
      const data = await UserService.update(selectedUser.value);
      selectedUser.value = new AppUser(data);
      if (isMyProfile.value) {
        // if editing yourself
        await store.dispatch(`auth/${AUTH_ACTIONS.RESET_TOKEN}`);
      }
      else {
        if(selectedUser.value.id === store.state.user.userId)
          store.state.user.clients = selectedUser.value.companies;
      }
      _onSaveSuccess('User was successfully updated 👍');
    } catch (err) {
      _onSaveFailure(null, err);
    }
  };

  const resetPassword = async () => {
    loading.value = true;
    saveSuccess.value = true;
    try {
      await UserService.triggerPasswordReset(user.value.email);
      loading.value = false;
      setTimeout(() => {
        saveSuccess.value = false;
      }, msgDisplayTime);
    } catch (err) {
      _onSaveFailure('Error resetting user password.', err);
    }
  };

  const resendRegistrationEmail = async () => {
    loading.value = true;
    saveSuccess.value = true;
    if (user.value.isRegistered) {
      loading.value = false;
      return;
    }

    try {
      await UserService.resendRegistrationEmail(<string>user.value.id);
      loading.value = false;
      setTimeout(() => {
        saveSuccess.value = false;
      }, msgDisplayTime);
    } catch (err) {
      _onSaveFailure('Error resending user registration email.', err);
    }
  };

  const _onSaveSuccess = async (msg) => {
    saveSuccess.value = true;
    loading.value = false;
    setTimeout(() => {
      saveSuccess.value = false;
      submitted.value = false;
    }, msgDisplayTime);
    store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: msg });
  };

  const _onSaveFailure = async (msg, err) => {
    error.value = err;
    loading.value = false;
    toast.add({
      severity: 'error',
      summary: '',
      detail: msg ?? error.value,
      life: msgDisplayTime,
      closable: false,
    });
  };

  const _validateUser = async () => {
    loading.value = true;
    submitted.value = true;
    const isValid = (await v$?.value.$validate()) ?? true;
    if (!isValid) {
      loading.value = false;
      // console.debug(`useUserActions._validateUser()`, isValid, submitted.value);
    }
    return isValid;
  };
  return {
    loading,
    error,
    passwordResetMessage,
    createUser,
    updateUser,
    resetPassword,
    resendRegistrationEmail,
  };
}
