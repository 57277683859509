import { IntLookup } from '@/models/lookup';
import { NotificationType } from '../notificationType';

export class NotificationSearchRow {

  id: string;
  libertyBellId: string;
  name: string;
  scheduleActive: boolean;
  isComplete: boolean;
  clients: IntLookup[];
  frequency: string;
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;
  editable: boolean;
  isReadOnly: boolean;
  type: NotificationType;
  lastRunOn: Date | null;
  description: string;
  descLoading: boolean;
  descLoaded: boolean;
  disabled: boolean;
  disabledReason: string;

  editUrl: string;

  constructor(props: Partial<NotificationSearchRow>) {
    Object.assign(this, props);
    this.clients = this.clients || [];
    this.editUrl = `/notification/${this.id}/1`;
  }

  get scheduleColorIndicator() {
    return this.scheduleActive ? 'green' : 'gray';
  }

  get scheduleTooltip() {
    return this.scheduleActive ? 'Schedule Active' : 'Schedule Inactive';
  }
}
