import { Filter } from '@/models/filters';
import { Lookup } from '@/models/lookup';
import DefaultFiltersService  from '@/services/defaultFiltersService';
import { MSG_MUTATIONS } from '@/store/modules/msg.module';
import { SEARCH_MODULE } from '@/store/modules/search.module';
import { onSuccess, onError } from '@/utils/toast/toastUtils';
import { Validation } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { Ref, ref } from 'vue';
import { useStore } from 'vuex';
import useAuthenticatedUser from './authenticatedUser';

export default function useDefaultFilterActions(userId: Ref<string>, v$: Ref<Validation>, selectedIndices: Ref<Array<Lookup<string>>>, submitted: Ref<boolean>, getSearchFieldFilters: () => Filter[]) {
  const loading = ref(false);
  const store = useStore();
  const toast = useToast();
  const { authenticatedUser }  = useAuthenticatedUser();

  const save = async () => {
    const isValid = await _validateFilters();
    if (!isValid) {
      return false;
    }
    await _saveDefaultFilters(userId.value);
    return true;
  };

  const _saveDefaultFilters = async (userId: string) => {
    try {
      const newFilter = getSearchFieldFilters();
      const { filters, indices } = await DefaultFiltersService.save(
                userId,
                newFilter,
                selectedIndices.value
              );
      if(userId === authenticatedUser.value.userId) {        
        await store.dispatch(`search/${SEARCH_MODULE.CLEAR_CURRENT_SEARCH}`);
        store.commit(`search/${SEARCH_MODULE.SET_SEARCH_DEFAULTS}`, {
                    filters,
                    indices,
                  });
      }

      onSuccess(toast, 'Default Filters Saved');
    } catch (err) {
      store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: err, type: 'error' });
      onError(toast, 'Error saving default filters');
    }
    loading.value = false;
  };

  const _validateFilters = async () => {
    loading.value = true;
    submitted.value = true;
    const isValid = (await v$?.value.$validate()) ?? true;
    if (!isValid) {
      loading.value = false;
      // console.debug(`useSearchFilterAction._validateFilters() - { isValid, submitted }`, isValid, submitted.value);
    }
    return isValid;
  };

  return { save };
}