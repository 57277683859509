import {SortOrder} from '@/models/sortOrder';
import {ActivityReportConfig} from '@/models/activity-dissemination';

export interface SearchCriteria {
  companyIds?: number[];
  query?: string;
  page?: number;
  pageSize?: number;
  sortBy?: keyof ActivityReportConfig;
  sortDir?: SortOrder
}

export const DEFAULTS = {
  PAGE_SIZE: 10,
  PAGE_SIZE_OPTIONS: [10, 25, 50, 100]
};
