import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import UserList from '@/views/admin/UserList.vue';
import EditUser from '@/views/admin/EditUser.vue';
import ManageUserActivityReports from '@/components/profile/ManageUserActivityReports.vue';
import EditActivityReportConfig from '@/views/activity-dissemination/EditActivityReportConfig.vue';
import ReaderDashboard from '@/views/analytics/ReaderDashboard.vue';
import ReaderCoDashboard from '@/views/analytics/ReaderCoDashboard.vue';
import ContentOverviewDashboard from '@/views/analytics/ContentOverviewDashboard.vue';
import ActivitiesToReviewList from '@/views/activity-review/ActivitiesToReviewList.vue';
import ClientDashboard from '@/views/analytics/ClientDashboard.vue';
import Print from '@/views/analytics/Print.vue';
import Search from '@/views/analytics/Search.vue';
import Onboarding from '@/views/onboarding/Onboarding.vue';
import OnboardingList from '@/views/onboarding/OnboardingList.vue';
import OnboardingReport from '@/views/onboarding/OnboardingReport.vue';
import SetPassword from '@/views/registration/SetPassword.vue';
import Register from '@/views/registration/Register.vue';
import ForgotPassword from '@/views/registration/ForgotPassword.vue';
import Error from '@/views/Error.vue';
import { UserRole } from '@/models/userRole';
import { NOTIFICATION_ROUTES } from './notificationRoutes';
import NewUser from '@/views/admin/NewUser.vue';
import { SETTING_ROUTES } from './settingRoutes';
import { PERFORMANCE_ROUTES } from './performanceRoutes';
import MostActiveCompaniesDashboard from '@/views/analytics/MostActiveCompaniesDashboard.vue';
import MostActiveReadersDashboard from '@/views/analytics/MostActiveReadersDashboard.vue';
import IndividualActivityReport from '@/views/individual-activity/IndividualActivityReport.vue';
import { Claim, ClaimAccessLevel, ClaimName } from '@/models/security';

function makeGuard(name: ClaimName, value: UserRole | ClaimAccessLevel | boolean = true) {
  return {
    meta: { auth: new Claim(name, value) }
  };
}

const GUARDS = {
  Client: makeGuard('role', 'Client'),
  Internal: makeGuard('role', 'Internal'),
  Admin: makeGuard('role', 'Admin'),
  OnboardingForm: makeGuard('manageOnboardingForms'),
  ReviewActivities: makeGuard('reviewActivities')
};

const ADMIN_ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/admin/users',
    name: 'user-list',
    component: UserList,
    ...GUARDS.Internal
  },
  {
    path: '/admin/user/',
    name: 'user-new',
    component: NewUser,
    ...GUARDS.Admin
  },
  // NOTE: Client users will have read-only access to user edit view
  {
    path: '/admin/user/:id?',
    name: 'user-edit',
    component: EditUser,
    ...GUARDS.Client
  },
  {
    path: '/admin/onboarding-list',
    name: 'onboarding-formlist',
    component: OnboardingList,
    ...GUARDS.OnboardingForm
  },


  {
    path: '/admin/onboarding/:id',
    name: 'onboarding-preview',
    component: OnboardingReport,
    ...GUARDS.Client
  }
];

const DISSEMINATION_CONTACT_ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/dissemination-contacts',
    name: 'dissemination-contacts',
    component: ManageUserActivityReports,
    ...GUARDS.Client
  },
  {
    path: '/dissemination-contact/:userId/:id?',
    name: 'dissemination-contact',
    component: EditActivityReportConfig,
    ...GUARDS.Client
  },
  {
    path: '/dissemination-contact/dashboard',
    name: 'dissemination-contact-dashboard',
    component: () => import(/* webpackChunkName: "dissemination" */ '@/views/activity-dissemination/ActivityReportDashboard.vue'),
  },
  {
    path: '/dissemination-contact/dashboard/:id/:activityReportId',
    name: 'dissemination-contact-dashboard-details',
    component: () => import(/* webpackChunkName: "dissemination" */ '@/views/activity-dissemination/ActivityReportDashboardDetails.vue')
  }
];

const AUTH_ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/set-password/:token',
    name: 'set-password',
    component: SetPassword
  },
  {
    path: '/register/:token',
    name: 'register',
    component: Register
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  { path: '/error', name: 'error', component: Error }
];

const ANALYTICS_ROUTES: Array<RouteRecordRaw> = [
  { path: '/', name: 'client-dash', component: ClientDashboard },
  {
    path: '/analytics/reader-dashboard/:email?',
    name: 'reader-dash',
    component: ReaderDashboard
  },
  {
    path: '/analytics/reader-co-dashboard/:company?/:id?/:orgName?',
    name: 'reader-co-dash',
    component: ReaderCoDashboard
  },
  { path: '/analytics/search', name: 'engagements-search', component: Search },
  { path: '/analytics/print', name: 'engagements-print', component: Print },
  {
    path: '/analytics/content-overview-dashboard',
    name: 'content-overview-dash',
    component: ContentOverviewDashboard
  },
  {
    path: '/analytics/most-active-companies-dashboard',
    name: 'most-active-company-dashboard',
    component: MostActiveCompaniesDashboard
  },
  {
    path: '/analytics/most-active-readers-dashboard',
    name: 'most-active-readers-dashboard',
    component: MostActiveReadersDashboard
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...ADMIN_ROUTES,
    ...DISSEMINATION_CONTACT_ROUTES,
    ...AUTH_ROUTES,
    ...ANALYTICS_ROUTES,
    ...NOTIFICATION_ROUTES,
    ...SETTING_ROUTES,
    ...PERFORMANCE_ROUTES,
    {
      path: '/review-activities',
      name: 'review-activities',
      component: ActivitiesToReviewList,
      ...GUARDS.ReviewActivities
    },
    {
      path: '/onboarding/:id/:step?',
      name: 'onboarding-form',
      component: Onboarding
    },
    {
      path: '/individual-activity/:activityReportConfigId/:activityReportId/:activityId',
      name: 'individual-activity',
      component: IndividualActivityReport
    }
  ],
});

//trackRouter(router);

export default router;
