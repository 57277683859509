<template>
  <div class="grid mb-3">
    <Message :closable="false">
      <b>Default filters</b> allow you to change the filters that are shown by default
      for all dashboards. Your current filter selections are displayed below. Edit your
      selections and click save to see them persist throughout Templeton<sup>&reg;</sup>.
    </Message>
  </div>
  <form class="mb-8" @submit.prevent="save">
    <div class="flex px-4 pb-2 sticky-header px-0">
      <div class="col-6" />
      <div class="col-6 flex flex-row-reverse align-items-center px-0">
        <Button type="submit" class="p-button-success p-button-raised p-button-rounded ml-3" label="Save" />
        <Button type="button" class="p-button-outlined p-button-rounded" label="Clear All"
          @click="clearFilterValues()" />

        <!-- <div
          class="success-message flex align-items-center justify-content-center p-0 m-0 pr-5"
          v-if="saveSuccess"
        >
          <span>Saved.</span>
          <span class="material-icons-outlined pl-1"> check_circle_outline </span>
        </div> -->
      </div>
    </div>
    <div ref="general" class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              General
            </div>
          </template>
          <template #content>
            <div class="grid px-3 pb-0">
              <div class="col-12 p-fluid pb-3">
                <span class="p-float-label">
                  <AutoComplete id="activityType" v-model="selectedIndices" :multiple="true"
                    :suggestions="filteredIndices" field="name" :dropdown="true" :complete-on-focus="true"
                    @complete="searchActivityTypes($event)" @focus="searchActivityTypes($event)" />
                  <label for="activityType">Activity Type</label>
                </span>
              </div>
              <div v-for="row in generalFields" :key="row.items[0].props?.label" class="col-12 p-fluid pb-3">
                <div v-for="f in displayableItems(row.items)" :key="f.props?.label">
                  <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f" :submitted="submitted"
                    :is-user-profile="!isMyProfile" />
                  <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                    :submitted="submitted" :is-user-profile="!isMyProfile" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div v-if="demographicFields != null" ref="demographic" class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              Demographic
            </div>
          </template>
          <template #content>
            <div class="grid px-3 pb-0">
              <div v-for="(row, i) in demographicFields" :key="row.items[0].props?.label"
                :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                  :class="getClass('child', row.items.length, j)">
                  <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                    :submitted="submitted" />
                  <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                    :submitted="submitted" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div v-if="allFirmographicFields != null" ref="firmographic" class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              Firmographic
            </div>
          </template>
          <template #content>
            <cp-reader-org-search-field v-if="isReaderLookUpExists" ref="childRef" @filter-changed="orgFilterChanged" />
            <div class="grid px-3 pb-0">
              <div v-for="(row, i) in allFirmographicFields" :key="row.items[0].props?.label"
                :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                  :class="getClass('child', row.items.length, j)">
                  <cp-compound-search-field-elements :is="f.type" v-if="f.props?.isCompound" v-bind="f.props"
                    :search-field="f" :submitted="submitted" />
                  <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                    :submitted="submitted" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div v-if="contextualFields != null" ref="contextual" class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              Contextual
            </div>
          </template>
          <template #content>
            <div class="grid px-3 pb-0">
              <div v-for="(row, i) in contextualFields" :key="row.items[0].props?.label" class=""
                :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                  :class="getClass('child', row.items.length, j)">
                  <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                    :submitted="submitted" />
                  <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                    :submitted="submitted" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import {computed, PropType, ref, watch} from 'vue';
import useVuelidate from '@vuelidate/core';
import {FILTER_COMPONENTS} from '@/components/searchFields';
import {AppUser} from '@/models/appUser';
import {Lookup} from '@/models/lookup';
import useAuthenticatedUser from '@/use/authenticatedUser';
import useDefaultFilterSecurity from '@/use/defaultFilterSecurity';
import useSearchFilterActions from '@/use/searchFilterActions';
import useSearchFilterSecurity from '@/use/searchFilterSecurity';
import {FilterProp, findFilter} from '@/utils/filters';
import useDefaultFilterActions from '@/use/defaultFilterActions';
import useSearchLookups from '@/use/searchLookups';
import {useRoute} from 'vue-router';
import {TermsFilter} from '@/models/filters';
import {FilterModel} from '@/models/search';
import useActivityFields from '@/use/activityFields';

export default {
  name: 'CpDefaultFilters',
  components: {
    ...FILTER_COMPONENTS
  },
  props: {
    modelValue: { type: Object as PropType<AppUser>, required: true },
    isMyProfile: { type: Boolean }
  },
  setup: function (props) {
    const selectedIndices = ref<Array<Lookup<string>>>([]);
    const filteredIndices = ref<Array<Lookup<string>>>([]);
    const submitted = ref(false);
    const filterProps = ref<FilterProp[]>([]);
    const editedUser = ref<AppUser>(props.modelValue);
    const childRef = ref();
    const route = useRoute();
    const { authenticatedUser } = useAuthenticatedUser();

    const routeUserId = computed(() => route.params['id'].toString());
    const { basicSearchableActivityFields } = useActivityFields();
    const fields = computed(() => [
      ...editedUser.value.activityFields || [], 
      ...basicSearchableActivityFields.value.map(x => x.field)
    ]);
    const isReaderLookUpExists = computed(() => fields.value.includes('ReaderOrgsLookup'));

    const {
      data: defaultFilterModel,
      getData: getDefaultFilters
    } = useDefaultFilterSecurity(routeUserId);

    const { onlineBgMap, orgQuestionsMap } = useSearchLookups();
    const {
      generalFields,
      demographicFields,
      contextualFields,
      allFirmographicFields,
      validations,
      filterModel,
      getSearchFieldRowClass,
      getSearchFieldFilters,
      updateData,
      clearData,
      displayableItems
    } = useSearchFilterSecurity(selectedIndices, filterProps, fields, orgQuestionsMap, onlineBgMap);
    const v$ = useVuelidate(validations, { filterModel });

    const { searchActivityTypes } = useSearchFilterActions(filterModel, v$, selectedIndices, filteredIndices, authenticatedUser, updateData, submitted);
    const { save } = useDefaultFilterActions(routeUserId, v$, selectedIndices, submitted, getSearchFieldFilters);

    const clearFilterValues = () => {
      clearData();
      updateData(filterModel.value);
    };

    const onUserChanged = () => {
      getDefaultFilters().then(() =>
        updateData(defaultFilterModel.value)
      );
    };
    watch(props, () => {
      onUserChanged();
    }, { deep: true, immediate: true });

    const orgFilterChanged = (model: Array<Lookup<any>>) => {
      let readerOrgFilter: TermsFilter = findFilter<TermsFilter>(filterModel.value.filters, 'ReaderOrgs');
      if (!readerOrgFilter) {
        readerOrgFilter = new TermsFilter({ field: 'ReaderOrgs', value: model });
        filterModel.value.filters.push(readerOrgFilter);
      }
      readerOrgFilter.value = model;
      onFilterModelChanged(filterModel.value);
    };

    const onFilterModelChanged = (filters: FilterModel) => {
      updateData(filters);
    };

    return {
      generalFields,
      demographicFields,
      contextualFields,
      allFirmographicFields,
      filterModel,
      submitted,
      getClass: getSearchFieldRowClass,
      searchActivityTypes,
      selectedIndices,
      filteredIndices,
      clearFilterValues,
      save,
      displayableItems,
      childRef,
      isReaderLookUpExists,
      orgFilterChanged
    };
  }
};
</script>

<style scoped lang="scss"></style>
