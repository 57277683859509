import { ref, Ref } from 'vue';
import DefaultFiltersService from '@/services/defaultFiltersService';
import { FilterConfigModel, FilterModel } from '@/models/search';

export default function useDefaultFilterSecurity(userId: Ref<string>) {
  const data = ref<FilterModel>(<FilterModel>{ filters: [], indices: [], config: new FilterConfigModel({ excludeFields: ['DateRange', 'RelativeDateRange', 'ActivityTypes'] }) });

  const getData = async () => {
    const response = await DefaultFiltersService.get(userId.value);
    // console.debug(`useDefaultFilterSecurity.getData() - { response }`, response);
    data.value.filters = response.filters;
    data.value.indices = response.indices;
  };


  return { data, getData };
}
