import { createFilter, Filter } from '@/models/filters';
import { Lookup } from '@/models/lookup';
import axios from 'axios';
import { FilterModel } from '@/models/search';

class DefaultFiltersService {
  async get(userId: string): Promise<FilterModel> {
    if (!userId) {
      return {filters: [], indices: []};
    }
    const {filters, indices } = (await axios.get<FilterModel>(`/api/default-filters/${userId}`)).data;

    const filterInstances = filters.map(f => createFilter(f.$type, f));
    return {filters: filterInstances, indices};
  }

  async save(userId: string, defaultFilters: Filter[], defaultIndices: Array<Lookup<string>>): Promise<FilterModel> {
    const {filters, indices } = (await axios.put<FilterModel>('/api/default-filters', { filters: defaultFilters, userId, indices: defaultIndices })).data;
    const filterInstances = filters.map(f => createFilter(f.$type, f));
    return {filters: filterInstances, indices};
  }
}

export default new DefaultFiltersService();
