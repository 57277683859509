<template>
  <Sidebar v-if="close" position="right" style="width: 60rem; background-color: #ffff">
    <form ref="form" @submit.prevent="emitColumnChange">
      <div class="m-5">
        <h2 class="mt-0 pt-0">
          Configure Export Columns
        </h2>
        <div class="flex flex-row-reverse m-3">
          <Button type="submit" class="p-button-success p-button-raised ml-3" label="Update" />
        </div>
        <cp-export-column-manager v-if="userEmail" :value-selected="selections" :user-id="userEmail"
          @set-selections="setSelections" />
      </div>
    </form>
  </Sidebar>
</template>
<script lang="ts">
import { cloneDeep } from 'lodash';
import { Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ExportColumnManager from '../admin/ExportColumnManager.vue';
import { Vue, Options } from 'vue-class-component';
import { event } from 'vue-gtag';
import { FieldCode } from '@/models/filters';

@Options({
  computed: {
    ...mapGetters('auth', ['userEmail']),
  },
  components: {
    'cp-export-column-manager': ExportColumnManager,
  },
  name: 'cp-export-column-config',
})
export default class ExportColumnConfig extends Vue {
  @Prop() value!: boolean; // used to show hide the drawer
  @Prop({ default: () => [] }) data1: FieldCode[];
  selections: FieldCode[] = [];
  close: boolean = true;

  userEmail!: string; // mapped from vuex

  @Watch('data1', { immediate: true })
  async onUserExportColumnsChanged(userColumns: FieldCode[]) {
    // if (isEqual(userColumns, this.selections)) {
    //   return;
    // }

    this.selections = cloneDeep(userColumns);
  }

  emitColumnChange() {
    event('search', {
      event_label: this.userEmail,
      event_category: 'columns-configure',
    });

    this.$emit('columnsSubmitted', this.selections);
    this.$emit('closeSidebar', false);
  }

  // resetColumns() {
  //   this.$gtag.event('reset', {
  //     event_label: this.userEmail,
  //     event_category: 'columns-configure'
  //   });
  //   this.$emit('columnReset');
  //   this.$emit('input', false);
  // }

  async setSelections(exportColumns: FieldCode[]) {
    this.selections = cloneDeep(exportColumns);
  }

  closeSidebar() {
    this.selections = cloneDeep(this.data1);
    this.$emit('closeSidebar', false);
  }
}
</script>
<style scoped lang="scss">
.scrollable {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}
</style>
