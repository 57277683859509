<template>
  <form ref="addForm" :disabled="modelValue.isReadOnly">
    <div class="flex col-12 pt-0">
      <h3>How often would you like to be notified?</h3>
    </div>
    <div class="flex p-fluid col-12 px-0 pt-2">
      <cp-action-item-list v-model="modelValue.frequency" :value-array="frequencies" layout="sm-card" />
    </div>
    <div v-if="isWeekly" class="grid pt-4 pl-3">
      <h3>What day of the week do you want to receive the notification?</h3>
      <div class="flex p-fluid col-12 px-0 pt-5">
        <span class="p-float-label p-fluid w-full">
          <Dropdown v-model="modelValue.dayOfWeek" :options="daysOfWeek" option-label="text" option-value="type"
            class="w-4" />
          <label for="filterByStatus">Frequency</label>
        </span>
      </div>
    </div>
    <div v-if="isMonthly" class="grid pt-4 pl-3">
      <h3>Select the day you will recieve your alert</h3>
      <div class="flex p-fluid col-12 px-0 pt-5">
        <span class="p-float-label p-fluid w-full">
          <Dropdown v-model="modelValue.dayOfMonth" :options="daysOfMonth" option-label="description"
            option-value="type" class="w-4" />
          <label for="filterByStatus">Frequency</label>
        </span>
      </div>
    </div>
    <div class="pt-4 pl-2">
      <h3 class="pb-2">
        Configure Export Columns
      </h3>
      <cp-export-column-manager v-if="modelValue.createdBy" :value-selected="modelValue.exportColumns"
        :user-id="modelValue.createdBy" :is-read-only="modelValue.isReadOnly" :is-collapsed="true"
        @set-selections="setSelections" />
      <cp-activate v-if="isEditWorkflow" v-model="modelValue.scheduleActive"
        text="Is this notification's schedule active?" />
    </div>
  </form>
</template>

<script lang="ts">
import { NotificationWorkflowMixin } from '@/mixins/notificationWorkflow.mixin';
import {
  DayOfMonthActionItem,
  DAY_OF_MONTH_ACTIONS,
} from '@/models/common/dayOfMonthActionItem';
import {
  DayOfWeekActionItem,
  DAY_OF_WEEK_ACTIONS,
} from '@/models/common/dayOfWeekActionItem';
import {
  FrequencyActionItem,
  FREQUENCY_ACTIONS,
  NotificationFrequencyRequest,
} from '@/models/notification/frequency';
import { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';
import ExportColumnManager from '../admin/ExportColumnManager.vue';
import NotificationStepperTitleBar from './common/NotificationStepTitleBar.vue';
import ActivateAlertField from './fields/ActivateAlertField.vue';
import ActionItemList from '@/components/common/ActionItemList.vue';
import { Options } from 'vue-class-component';
import { WorkflowStepEnum } from '@/models/notification';
import notificationService from '@/services/notificationService';

@Options({
  name: 'cp-notification-frequency-format-step',
  computed: {
    ...mapGetters('auth', ['userEmail']),
  },
  components: {
    'cp-activate': ActivateAlertField,
    'cp-stepper-title-bar': NotificationStepperTitleBar,
    'cp-export-column-manager': ExportColumnManager,
    'cp-action-item-list': ActionItemList,
  },
})
export default class NotificationFrequencyFormatStep extends mixins(
  NotificationWorkflowMixin
) {
  // from vuex
  userEmail!: string;

  activate: boolean;

  // filters
  frequencies: FrequencyActionItem[] = [...FREQUENCY_ACTIONS];
  daysOfWeek: DayOfWeekActionItem[] = [...DAY_OF_WEEK_ACTIONS];
  daysOfMonth: DayOfMonthActionItem[] = [...DAY_OF_MONTH_ACTIONS];

  mounted() {
    this.$emit('is-loaded', true);
  }

  /**
   * Calls the Liberty Bell API and saves the frequency to the loaded Notification
   *
   * @returns Notification Id
   */
  async save(): Promise<string> {
    try {
      const id = this.$route.params['id'].toString();
      const dayOfWeek = this.modelValue?.dayOfWeek || this._defaultDayOfWeek;
      const dayOfMonth = this.modelValue?.dayOfMonth || this._defaultDayOfMonth;
      const req: NotificationFrequencyRequest = {
        user: this.userEmail,
        frequency: this.modelValue.frequency || 'Monthly',
        dayOfWeek: this.modelValue.frequency === 'Weekly' ? dayOfWeek : undefined,
        dayOfMonth:
          this.modelValue.frequency === 'Monthly' ? dayOfMonth : undefined,
        activateSchedule: this.isEditWorkflow ? this.modelValue.scheduleActive : false,
        exportColumns: this.modelValue.exportColumns ?? [],
        lastStepCompleted: this.modelValue.getLastAvailableStep(WorkflowStepEnum.Frequency)
      };

      await notificationService.setFrequency(id, req);
      //this.$emit("update:modelValue", this.modelValue);
      return Promise.resolve(this.modelValue.id);
    } catch (err) {
      // Send error message back to parent
      this.showError([this.ERROR_MESSAGES.save, this.ERROR_MESSAGES.sysAdmin]);
      return Promise.reject(err);
    }
  }

  /**
   * Update the data model when the activation status is changed
   */
  updateActivate(activate: boolean): void {
    this.modelValue.scheduleActive = activate;
  }

  get isWeekly(): boolean {
    return this.modelValue.frequency === 'Weekly';
  }

  get isMonthly(): boolean {
    return this.modelValue.frequency === 'Monthly';
  }

  private get _defaultDayOfWeek() {
    return DAY_OF_WEEK_ACTIONS.filter((x) => x.active)[0].type;
  }
  private get _defaultDayOfMonth() {
    return DAY_OF_MONTH_ACTIONS.filter((x) => x.active)[0].type;
  }

  async setSelections(exportColumns) {
    this.modelValue.exportColumns = exportColumns;
  }
}
</script>

<style lang="scss"></style>
