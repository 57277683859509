import { computed, Ref, ref, watch } from 'vue';
import { Question } from '@/models/user-service';

import {
    callSearchFieldModelFunction,
    initSearchFilter,
    ActivityFieldCategory, SearchFieldDisplayDependency,
    SearchFieldMetaData,
    searchFieldModel,
    searchFieldModelValue,
    SearchFieldRow,
} from '@/components/searchFields/metadata';

import { cloneDeep, isEqual, uniq, flatten } from 'lodash';
import { Lookup } from '@/models/lookup';
import { DateRangeFilter, FieldCode, Filter, TermsFilter } from '@/models/filters';
import { FilterModel } from '@/models/search';
import { INDEX_LOOKUPS } from '@/models/activity-dissemination';
import { FilterProp, findFilter, setPropOverrides } from '@/utils/filters';
import {
    minLength
} from '@vuelidate/validators';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/models/common/dateFormats';

export default function useSearchFilterSecurity(selectedIndices: Ref<Array<Lookup<string>>>, props: Ref<FilterProp[]>, fields: Ref<FieldCode[]>, orgQuestionsMap: Ref<Record<number, Question>>,
    onlineBgMap: Ref<Record<string, string>>) {
    const searchFields = ref<SearchFieldRow[]>([]);
    const excludeOrgSearchFields = ref<string[]>([]);
    const firstWatch = ref(false);
    const filterModel = computed(() => {
        const clonedSearchFields = cloneDeep(searchFields.value);
        _clearFieldsWithDisplayDependency(clonedSearchFields);
        const filters = clonedSearchFields
            .flatMap((x) => x.items)
            .filter((x) => !excludeOrgSearchFields.value.includes(searchFieldModelValue(x, 'field')))
            .filter((x) => {
                try {
                    return !callSearchFieldModelFunction(x, 'isEmpty');
                } catch (error) {
                    console.log('error', x);
                }
            })
            .map((x) => x.model);
        // NOTE: flatten is used due to the possible presence of compound filters
        return <FilterModel>{ filters: flatten(filters), indices: selectedIndices.value };
    });

    const generalFields = computed(() => _getByCategory('General'));
    const demographicFields = computed(() => _getByCategory('Demographics'));
    const contextualFields = computed(() => _getByCategory('Contextual'));
    const firmographicFields = computed(() => _getByCategory('FirmographicGeneral'));
    const customFirmographicFields = computed(() =>
        _getByCategory('FirmographicLifeSciences', 'FirmographicMedicalDevices')
    );

    const allFirmographicFields = computed(() => {
        const f = firmographicFields.value.concat(
            customFirmographicFields.value.filter((x) =>
                x.items.some(
                    (i) => !excludeOrgSearchFields.value.includes(searchFieldModelValue(i, 'field'))
                )
            )
        );
        f.flatMap(i => i.items)
            .filter(i => i.props?.questionId)
            .forEach(i => {
                const q = orgQuestionsMap.value[i.props?.questionId || 0];
                (i.props || {}).label = q?.title;
                if (i.type?.name !== 'cp-terms-search-field') {
                    return;
                }
                _setOrgFieldOptions(q, i);
            });
        return f;
    });

    const onlines = computed(() => {
        const onlinesSearchField = searchFields.value
            .flatMap((x) => x.items)
            .find((r) => (
                searchFieldModelValue(r, 'field') === 'onlines'
            ))?.model as TermsFilter;
        return onlinesSearchField ? onlinesSearchField.value.map((x) => x.id) : [];
    });

    const dateRangeText = computed(() => {
        const dtFilter = findFilter<DateRangeFilter>(filterModel.value.filters, 'DateRange');
        if (!dtFilter?.value) {
            return '';
        }
        return `RESULTS FROM ${dayjs(dtFilter.value.from).format(DATE_FORMAT)} - ${dayjs(dtFilter.value?.to || new Date()).format(DATE_FORMAT)}`.toUpperCase();
    }
    );

    const validations = computed(() => {
        const rules = {
            filterModel: {
                filters: { minLength: minLength(1) }
            }
        };
        return rules;
    });

    // Add watcher for onlines and call onlinesChanged when watch is triggered
    const onlinesChanged = (prev: any, cur: any) => {
        if (firstWatch.value || !isEqual(prev, cur)) {
            firstWatch.value = false;

            const bgCodes = uniq(onlines.value.map((o) => onlineBgMap.value[o]));
            excludeOrgSearchFields.value = customFirmographicFields.value
                .flatMap((f) => f.items)
                .filter(
                    (i) =>
                        i.props?.questionId &&
                        orgQuestionsMap.value[
                        i.props.questionId
                        ] &&
                        !bgCodes.includes(
                            orgQuestionsMap.value[
                                i.props.questionId
                            ]?.businessGroup.toUpperCase()
                        )
                )
                .map((i) => searchFieldModelValue(i, 'field'));
        }
    };

    const getSearchFieldRowClass = (scope: string, length: number, index: number, isCompound = false): string => {
        if (scope == 'main') {
            return isCompound ? 'col-12 p-fluid ml-0' : (length > 1 ? 'grid' : '') + ' col-12 p-fluid ml-0 pb-3';
        }
        if (scope == 'child') {
            return length > 1
                ? 'col-' + 12 / length + (index % 2 == 0 ? ' pl-0 pr-3 pb-0' : ' pl-3 pr-0 pb-0')
                : '';
        }
        return '';
    };

    const getSearchFieldFilters = () => searchFields.value
        .flatMap((x) => x.items)
        .filter((x) => !excludeOrgSearchFields.value.includes(searchFieldModelValue(x, 'field')))
        .filter((x) => {
            try {
                return x.props?.isCompound ? (x.model as Filter[]).some(x => !x.isEmpty()) : !callSearchFieldModelFunction(x, 'isEmpty');
            } catch (error) {
                console.log('error', x);
            }
        })
        .flatMap((x) => x.props?.isCompound ? x.model as Filter[] : [x.model as Filter]);

    const updateData = (filters: FilterModel) => {
        const clone = cloneDeep(filters);
        let visibleFields = fields.value;
        //filter-out based on filterConfig
        if (filters.config) {
            visibleFields = visibleFields.filter(f => !(filters.config?.excludeFields || []).includes(f));
        }
        searchFields.value = initSearchFilter(visibleFields, clone.filters);
        _clearFieldsWithDisplayDependency(searchFields.value);
        selectedIndices.value = clone.indices.filter(i => INDEX_LOOKUPS.map(l => l.id).includes(i.id));
        setPropOverrides(searchFields.value.flatMap(f => f.items), props.value);
    };

    const displayableItems = (items: SearchFieldMetaData[]) => items.filter(x => {
        const dependency = x.displayDependency;
        if (!dependency) {
            return true;
        }
        return _displayDependencySatisfied(dependency, searchFields.value.flatMap(x => x.items));
    });

    const displayableFilters = computed(() => {
        const displayable = displayableItems([
            ...generalFields.value.flatMap(x => x.items),
            ...demographicFields.value.flatMap(x => x.items),
            ...allFirmographicFields.value.flatMap(x => x.items),
            ...contextualFields.value.flatMap(x => x.items)]);

        return filterModel.value.filters.filter(x => displayable.some(d => d.props?.isCompound
            ? (d.model as Filter[])[0].field === x.field
            : (d.model as Filter).field === x.field)
        );
    });

    const clearData = () => {
        const filters = getSearchFieldFilters();
        selectedIndices.value = [];
        filters.forEach(f => f.clearValue());
    };

    const _getByCategory = (...categories: ActivityFieldCategory[]): SearchFieldRow[] => searchFields.value.filter((f) => f.category && [...categories].includes(f.category));

    const _setOrgFieldOptions = (q: Question, i: SearchFieldMetaData) => {
        let opts = q?.options || [];
        const bgCodes = uniq(onlines.value.map((o) => onlineBgMap.value[o]));
        if (bgCodes?.length) {
            opts = opts.filter((o) =>
                o.businessGroups?.length
                    ? o.businessGroups.some((x) => bgCodes.includes(x))
                    : true
            );
        }
        const lookups: Array<Lookup<number>> = opts.map((x) => ({
            id: x.id,
            name: x.text,
        }));
        (i.props || {}).optionsGetter = () => Promise.resolve(lookups);
        const tf = <TermsFilter>searchFieldModel(i);
        // Filter out selected options which are not applicable.
        tf.value = tf.value.filter((x) => lookups.some((y) => y.id === x.id));
    };

    const _clearFieldsWithDisplayDependency = (searchFieldRows: SearchFieldRow[]) => {
        const itemsWithDisplayDependency = searchFieldRows.flatMap(x => x.items).filter(x => x.displayDependency);
        itemsWithDisplayDependency.forEach(x => {
            const dependency = x.displayDependency;
            if (!dependency) {
                return;
            }
            const satisfied = _displayDependencySatisfied(dependency, searchFieldRows.flatMap(x => x.items));

            if (!satisfied) {
                (x.model as Filter).clearValue();
            }
        });
    };

    const _displayDependencySatisfied = (dependency: SearchFieldDisplayDependency, items: SearchFieldMetaData[]) => items.some((x) => {
        const model = x.model as Filter;
        return model.field === dependency.field &&
            dependency.values
                .some(v => v === model.value
                    || v?.toString() === model.value?.toString()
                    || flatten([model.value]).includes(v));
    });

    watch(onlines, onlinesChanged);

    return {
        searchFields,
        onlines,
        generalFields,
        dateRangeText,
        demographicFields,
        contextualFields,
        firmographicFields,
        customFirmographicFields,
        allFirmographicFields,
        filterModel,
        validations,
        getSearchFieldFilters,
        getSearchFieldRowClass,
        updateData,
        clearData,
        displayableItems,
        displayableFilters
    };
}
