import { Claim } from '@/models/security/claim';

export const SETTING_ROUTES = [
  {
    path: '/settings/integrations',
    name: 'settings-integrations',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Integrations.vue'),
    meta: { auth: new Claim('role', 'Admin') }
  }
];
