import { computed, ref, Ref } from 'vue';
import {
  required,
  email,
  maxLength,
  requiredIf,
  minValue,
} from '@vuelidate/validators';
import {
  ActivityReportConfig,
  ActivityReportType,
  SendDayData,
  SEND_DAYS_LIST,
} from '@/models/activity-dissemination';

export default function useActivityReportConfigSecurity(
  selectedConfig: Ref<ActivityReportConfig>
) {
  const config = ref(selectedConfig.value);
  const sendDayData = ref<SendDayData[]>(SEND_DAYS_LIST);

  const validations = computed(() => {
    const rules = {
      config: {
        email: { required, email },
        firstName: { required, maxLength: maxLength(50) },
        lastName: { required, maxLength: maxLength(50) },
        companyId: { required },
        reportType: { required },
        sendDay: { minValue: minValue(1) },
        webhookUrl: {
          required: requiredIf(
            () =>
              config.value.reportTypeEnum === ActivityReportType.Webhook
          ),
        }
      },
    };
    return rules;
  });

  return { config: config, sendDayData, validations };
}
